import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Button } from "components/Button";
import { MONEY_MASK } from "constants/masks";
import { ROUTES } from "constants/routes";
import { useGlobalState } from "contexts/global-state";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiService } from "services/api";
import { contractSchema } from "./form-validation";
import { ModalConfirm } from "components/ModalConfirm";
import Text from "components/Text";
import { INDICATOR_CODE, LEAD_DATA } from "constants/keys";
import LoadingFullPage from "components/LoadingFullPage";

interface FormContractProps {
  initialFields: FieldsRequest[];
  service: Services;
  client: Clients;
}

interface FieldsRequest {
  name: string;
  cpf: string;
  email: string;
}

export default function FormContract({
  initialFields,
  service,
  client,
}: FormContractProps) {
  const navigate = useNavigate();
  const [fields, setFields] = useState<FieldsRequest[]>([]);
  const [discountValue] = useState<number>(0);
  const [hasOpenModalUseSameData, setHasOpenModalUseSameData] = useState<boolean>(true);
  const [hasUseSameData, setHasUseSameData] = useState<boolean>(false);
  const [isLoadingSubmitForm, setIsLoadingSubmit] = useState<boolean>(false);

  const totalServiceValue =  useMemo(() => {
    if(service.quantity === 1 || !service.quantity) {
      return  service.value * fields.length
    }
    return service.value
  },[fields.length, service.quantity, service.value])
  const { openSuccessToast, openErrorToast } = useGlobalState();

  const {
    register,
    handleSubmit,
  } = useForm<ContractsApiRequest>({
    resolver: yupResolver(contractSchema),
  });

  

  const removeField = useCallback(
    (index: number) => {
      setFields((fields) =>
        fields.filter((_, fieldIndex) => fieldIndex !== index)
      );
    },
    [setFields]
  );

  useEffect(() => {setFields(initialFields)}, [initialFields])

  const handleChangeFormValue = useCallback(
    (index: number, key: string, value: string) => {
      setFields((fields) => {
        const newFields: FieldsRequest[] = fields.map((fieldsRequest, i) => {
          if (i !== index) {
            return fieldsRequest;
          }

          return {
            ...fieldsRequest,
            [key]: value,
          };
        });
        return newFields;
      });
    },
    [setFields]
  );

  const addField = useCallback(() => {
    setFields((fields) => [
      ...fields,
      {
        name: "",
        cpf: "",
        email: "",
      },
    ]);
  }, [setFields]);

  const generateForms = useMemo(() => {
    return (
      <>
        {fields.map((item, index) => {
          const disabled = index === 0 && hasUseSameData
          return (
            <>
              <Grid item xs={12} md={6} key={`name-${index}`}>
                <TextField
                  id="application_name"
                  label="Nome"
                  required
                  fullWidth
                  disabled={disabled}
                  value={item.name}
                  onChange={(evt) =>
                    handleChangeFormValue(index, "name", evt.target.value)
                  }
                />
              </Grid>
              
              <Grid item xs={12} md={1}>
                <Button
                  variant="contained"
                  disabled={disabled}
                  fullWidth
                  onClick={() => removeField(index)}
                >
                  <DeleteForeverIcon style={{ marginRight: '0px' }} />
                </Button>
              </Grid>
            </>
          );
        })}
        
         
      </>
    );
  }, [fields, handleChangeFormValue, hasUseSameData, removeField]);

  const addNewContract = useMutation({
    mutationFn: async (contracts: ContractsApiRequest) => {
      setIsLoadingSubmit(true)
      const contract = await ApiService.Contracts.create(contracts);
      const findFirstField = fields[0]
      for (const field of fields) {
        await ApiService.Forms.create({
          applicant_name: field.name,
          cpf: field.cpf,
          email: field.email,
          client_id: client.id,
          contract_id: contract.id,
          form_type_id: service.form_type_id,
          main: findFirstField.name === field.name
        });
      }
      return contract
    },
    onSuccess: (data) => {
      setIsLoadingSubmit(false)
      openSuccessToast({
        message: `O contrato foi adicionado com sucesso!`,
      });
      navigate(ROUTES.CONTRACTS.PAYMENT(`${data.id}`));
    },
    onError: (error: ApiError) => {
      setIsLoadingSubmit(false)
      openErrorToast({
        message: `Erro ao adicionar este contrato: ${error?.message}`,
      });
    },
  });

  const onSubmit: SubmitHandler<ContractsApiRequest> = (
    contracts: ContractsApiRequest
  ) => {
    const indicatorCode = sessionStorage.getItem(INDICATOR_CODE)
    const leadStorage = sessionStorage.getItem(LEAD_DATA)
    if(leadStorage){
      const lead = JSON.parse(leadStorage)
      contracts.lead_id = lead.id
    }
    addNewContract.mutate({
      ...contracts,
      service_id: service.id,
      client_id: client.id,
      total_value: totalServiceValue,
      amount_charged: totalServiceValue,
      discount: discountValue,
      coupon_discount_value: discountValue,
      application_type: fields.length > 1 ? "joint" : "individual",
      status: "awaiting_payment",
      indicator_code: indicatorCode,
    });
  };



  return (
    <>
    {isLoadingSubmitForm && <LoadingFullPage />}
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardContent>
          <Grid container spacing={2} xs={12} direction={{ xs: 'column', md: 'row' }}>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Serviço"
                value={service?.name}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={3} >
              <TextField
                fullWidth
                label="Valor final"
                id="amount_charged"
                value={MONEY_MASK(totalServiceValue)}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                {...register("amount_charged")}
              />
          </Grid>

            
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 1, paddingLeft: "5px", fontWeight: "bold" }}
              >
                Aplicantes
              </Typography>
            </Grid>

            {generateForms}
            
          </Grid>
         <Box sx={{ display: 'flex', width: 'calc(100% - 16px)',flexDirection: { xs: 'column', md: 'row'}, margin: '1.5rem 0', gap: '1rem' }}>
         <Button
              variant="contained"
              color="primary"
              onClick={() => addField()}
              disabled={service.quantity === 1 ? false : service.quantity ? fields.length === service.quantity : false}
              
            >
              Adicionar participante
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{
                marginLeft: "auto",
                display: "block",
              }}
              
            >
              Proximo
            </Button></Box>
        </CardContent>
      </Card>
    </form>
    <ModalConfirm
      visible={hasOpenModalUseSameData}
      onClose={() => {
        setFields(oldFields => {
          const copyFields = JSON.parse(JSON.stringify(oldFields))
          copyFields[0].cpf = ''
          copyFields[0].name = ''
          copyFields[0].email = ''
          return copyFields
        })
        setHasOpenModalUseSameData(false)
      }}
      onAccept={() => {
        setHasOpenModalUseSameData(false)
        setHasUseSameData(true)

      }}
    >
     <div style={{ marginTop: '10px'}}>
     <Text>O contrato de prestação de serviços será feito em seu nome, você é também um solicitante de visto?</Text>
     </div>
    </ModalConfirm>
    </>
  );
}
